import revive_payload_client_U6TKvwituk from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._wbqwc74tbi7mmtbs6xuw4y22iu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gocPqFmXEa from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._wbqwc74tbi7mmtbs6xuw4y22iu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_URybyJm11G from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._wbqwc74tbi7mmtbs6xuw4y22iu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_mjvz2NLZH7 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._wbqwc74tbi7mmtbs6xuw4y22iu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_I5Agz8WSC7 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._wbqwc74tbi7mmtbs6xuw4y22iu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_FgcMVoLpqC from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._wbqwc74tbi7mmtbs6xuw4y22iu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GvXwf4ItFD from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._wbqwc74tbi7mmtbs6xuw4y22iu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ytvl7A2qwV from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.5.4_vue@3.5.8_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_Y41yDYkALC from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._wbqwc74tbi7mmtbs6xuw4y22iu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/render/project/src/.nuxt/primevue-plugin.mjs";
import plugin_client_MbpxInJ2Er from "/opt/render/project/src/node_modules/.pnpm/@primevue+nuxt-module@4.0.7_patch_hash=wiemqw53ii2cuxy56mkzosavte_@babel+parser@7.25.6_magica_wpxgbduzb5fz62pvbdwdd4xi34/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import _01_directus_80HGRry5qP from "/opt/render/project/src/plugins/01.directus.ts";
import _02_refreshToken_WDp7SsBhVi from "/opt/render/project/src/plugins/02.refreshToken.ts";
import _03_configOverrides_GceWKGsitr from "/opt/render/project/src/plugins/03.configOverrides.ts";
import _04_theme_pZjsSCyjPE from "/opt/render/project/src/plugins/04.theme.ts";
export default [
  revive_payload_client_U6TKvwituk,
  unhead_gocPqFmXEa,
  router_URybyJm11G,
  payload_client_mjvz2NLZH7,
  navigation_repaint_client_I5Agz8WSC7,
  check_outdated_build_client_FgcMVoLpqC,
  chunk_reload_client_GvXwf4ItFD,
  plugin_vue3_Ytvl7A2qwV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Y41yDYkALC,
  primevue_plugin_egKpok8Auk,
  plugin_client_MbpxInJ2Er,
  _01_directus_80HGRry5qP,
  _02_refreshToken_WDp7SsBhVi,
  _03_configOverrides_GceWKGsitr,
  _04_theme_pZjsSCyjPE
]